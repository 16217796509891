<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<wp_login v-model="site" :key="wireframe_preview" :workspace="workspace" :redirect="wireframe_preview" v-on:update:submitted="update_submitted" :preview="preview" />

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<design_nav v-if="$route.name.includes( 'site.design' )" :site="site" :preview="staging" />

				<build_nav v-if="$route.name.includes( 'site.build' )" :site="site" :preview="staging" :wpurl="preview" />

				<launch_nav v-if="$route.name.includes( 'site.launch' )" :site="site" :preview="staging" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="bg-white overflow-hidden flex-grow-1 position-relative" style="padding-bottom: 50px;">

						<div class="position-absolute top-50 start-50 translate-middle">
							<span class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</span>
						</div>
						
						<iframe 
						v-on:load="load_iframe"
						name="iframe-preview" 
						class="w-100 h-100 border-bottom position-relative" 
						:class="[(!iframe_loaded || !wp_submitted ? 'opacity-0' : '')]"
						></iframe>
					</div>					

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import design_nav from '@/components/design_nav'
import build_nav from '@/components/build_nav'
import launch_nav from '@/components/launch_nav'
import wp_login from '@/components/wp_login'

export default {
	name: 'site.wordpress',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		design_nav,
		build_nav,
		launch_nav,
		wp_login
	},

	data() {
		return {
			loading: false,
			iframe_loaded: false,
			wp_submitted: false,
			site: null,
			version: 0,
			login: {
				user: null,
				password:null,
				database: null
			},
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/storage/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		wireframe_preview()
		{
			var wireframe = ( this.site['settings'] && this.site['settings']['override-wireframe'] ? '' : 'true' )

			wireframe += '&areoi-view=design'

			var url = this.preview

			switch( this.$route.name ) {

				case 'site.build.content.pages':
					url += '/wp-admin/edit.php?post_type=page&version=' + this.version
				break;

				case 'site.build.content.menus':
					url += '/wp-admin/nav-menus.php?version=' + this.version
				break;

				case 'site.design.content.menus':
					url += '/wp-admin/nav-menus.php?version=' + this.version + '&wireframe=' + wireframe
				break;

				case 'site.design.content.header':
					url += '/wp-admin/site-editor.php?postType=wp_template_part&postId=areoi-child%2F%2Fheader&categoryId=header&categoryType=wp_template_part&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.build.content.header':
					url += '/wp-admin/site-editor.php?postType=wp_template_part&postId=areoi-child%2F%2Fheader&categoryId=header&categoryType=wp_template_part&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.build.content.footer':
					url += '/wp-admin/site-editor.php?postType=wp_template_part&postId=areoi-child%2F%2Ffooter&categoryId=footer&categoryType=wp_template_part&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.design.content.footer':
					url += '/wp-admin/site-editor.php?postType=wp_template_part&postId=areoi-child%2F%2Ffooter&categoryId=footer&categoryType=wp_template_part&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.design.content.post-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Fsingle&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.design.content.search-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Fsearch&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.design.content.notfound-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2F404&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.design.content.archive-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Farchive&canvas=edit&version=' + this.version + '&action=edit&wireframe=' + wireframe
				break;

				case 'site.build.content.post-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Fsingle&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.build.content.search-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Fsearch&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.build.content.notfound-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2F404&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.build.content.archive-template':
					url += '/wp-admin/site-editor.php?postType=wp_template&postId=areoi-child%2F%2Farchive&canvas=edit&version=' + this.version + '&action=edit'
				break;

				case 'site.build.extend.theme':
					url += '/wp-admin/theme-editor.php?version=' + this.version
				break;

				case 'site.build.extend.plugins':
					url += '/wp-admin/plugin-editor.php?version=' + this.version
				break;

				case 'site.build.extend.install-plugins':
					url += '/wp-admin/plugin-install.php?version=' + this.version
				break;

				case 'site.launch':
					url += '/wp-admin/admin.php?page=aaio-dashboard&section=tracking&version=' + this.version
				break;

				case 'site.launch.gdpr':
					url += '/wp-admin/admin.php?page=aaio-dashboard&section=gdpr&version=' + this.version
				break;

				case 'site.launch.redirects':
					url += '/wp-admin/admin.php?page=aaio-dashboard&section=redirects&version=' + this.version
				break;

				case 'site.launch.performance':
					url += '/wp-admin/edit.php?post_type=page&version=' + this.version
				break;
			}			

			return url
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		update_submitted( e )
		{
			this.wp_submitted = e
		},

		load_iframe()
		{
			this.iframe_loaded = true;
		},
	}
}
</script>